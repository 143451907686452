// Mostly copied from and modeled after "recent-publications" component and "recent-list" component.
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-media-list",
  templateUrl: "./media-list.component.html",
  styleUrls: ["./media-list.component.scss"],
})
export class MediaListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  openInNewTab(url): void {
    window.open(url, "_blank");
  }

  openLinkInNewWindow(url): void {
    window.open(url, "newwindow");
  }

  // tslint:disable-next-line:max-line-length
  publications: Array<{
    title: string;
    publishDate?: string;
    link: string;
    newspaper_name?: string;
    image?: string;
    language?: string;
    other_links?: Array<{
      link: string;
      newspaper_name?: string;
      publishDate?: string;
    }>;
  }> = [
    // Notes on November 16, 2022:
    // <<Top 3 are English "famous", Next are most popular languages, the rest of the links, note of NK. Ideally, newest at the top.>>:
    // November 16, 2022 <<Top 3 are English "famous">>:
    /* THis one is moving to the top of the page:
      {
        title: "How a Greek-American Orthodontist Brought Fastbraces to the World",
        publishDate: "March 29, 2024",
        link: "https://disruptmagazine.com/how-a-greek-american-orthodontist-brought-fastbraces-to-the-world/",
        newspaper_name: "Disrupt Magazine",
        image: "disimagee.png"
    }, */
    {
      title: "Fastbraces, braces at the dental office that help straighten teeth fast!",
      publishDate: "July 17, 2024",
      // link: "https://www.msn.com/en-us/news/other/fastbraces-braces-at-the-dental-office-that-help-straighten-teeth-fast/ar-BB1q8pPX",
      // to do: later get a local pdf for this article
      link: "https://fastbraces.com/assets/articles/Microsoft_start_July_2024.pdf",
      newspaper_name: "Microsoft Start",
      image: "msstart_BB1q8kP6.png",
    },
    {
      title: "How a Greek-American Orthodontist Brought Fastbraces to the World",
      publishDate: "", // Unknown
      // link: "https://disruptmagazine.com/how-a-greek-american-orthodontist-brought-fastbraces-to-the-world",
      // update link to be local, since the article was removed:
      link: "https://fastbraces.com/assets/articles/1_How_a_Greek_American_Disrupt.pdf",
      newspaper_name: "Disrupt Magazine",
      image: "disimagee.png",
    },
    {
      title: "Fastbraces: The Science Of Alveolar Bone Remodeling",
      publishDate: "May 31, 2024",
      // link: "https://www.medicaldaily.com/fastbraces-science-alveolar-bone-remodeling-471794",
      // update link to be local, since the article will be removed:
      link: "https://fastbraces.com/assets/articles/2_Fastbraces_Science_Remodeling.pdf",
      newspaper_name: "Medical Daily",
      image: "disimagee.png",
    },
    // [1] Moved up: this Yahoo link was at the bottom, not it is replaced, but it's sub-link "AP News"
    {
      title:
        "Orthodontic Influencer, Dr. Evangelos Viazis, Talks on the Revolutionary Fastbraces Orthodontic Technology",
      publishDate: "September 9, 2022",
      link: "https://www.yahoo.com/now/orthodontic-influencer-dr-evangelos-viazis-231735856.html",
      newspaper_name: "Yahoo News",
      image: "b5f3937561.jpeg", // Repeated!
      // language: "english",
    },

    // [2] Moved up:
    {
      title:
        "Annual Global Business Conference to be held in Dallas on the Revolutionary Fastbraces® Orthodontic Technology",
      publishDate: "September 19, 2022",
      link: "https://apnews.com/article/technology-health-texas-dallas-23af29a487149d7210ebdb9b12c38022",
      newspaper_name: "AP News",
      image: "apnews_1000.jpeg",
      // language: "english",
      other_links: [
        {
          link: "https://finance.yahoo.com/news/annual-global-business-conference-held-023400663.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAIBliEQcz4UDgRnWPBHd866CStAozrTFJkVM2K2PtLAmPoR7ci4LG3jEJRpdhrLGCJF2u-RCLNm8I6u1yEpEfKbsCR-M1pKdM8VeV5b2DnUPPTKAy3lelm4vPuwlNAjWvkmFjq_5XyzfXNdWVrE5ZXLUsFBlnSuBYJTAo5jXUsUr",
          newspaper_name: "Yahoo Finance",
          publishDate: "September 21, 2022",
        },
      ],
    },

    // [3] Moved up:
    {
      title:
        "Annual Global Business Conference to Be Held in Dallas on the Fastbraces Orthodontic Technology",
      publishDate: "September 21, 2022",
      link: "https://www.bloomberg.com/press-releases/2022-09-22/annual-global-business-conference-to-be-held-in-dallas-on-the-fastbraces-orthodontic-technology",
      newspaper_name: "Bloomberg",
      image: "bloombers672678.png",
    },

    // November 16, 2022 <<Next are most popular languages>>:
    // November 14, 2022 ----:
    {
      title:
        "Dr. Evangelos Viazis, la parsonne derrière la popularité des Fastbraces® en Grèce",
      // publishDate: "September xx, 2022",
      link: "https://les-innovations.fr/dr-evangelos-viazis-la-parsonne-derriere-la-popularite-des-fastbraces-en-grece/",
      newspaper_name: "Les Innovations",
      image: "dr-evangelos-viazisewee.jpeg",
      language: "french",
    },

    // ---- end of November 14 additions.

    {
      title:
        "خاص| من اليونان للعالم.. قصة الطبيب إيفانجيلوس فيازيس وثورة علاج الأسنان",
      publishDate: "October 27, 2022",
      link: "https://www.elbalad.news/5501237",
      newspaper_name: "Sada El-Balad",
      image: "hjgt787rfyfjyhjg78.jpg",
      language: "arabic",
    },
    {
      title:
        "Influenciador ortodôntico, Dr. Evangelos Viazis, fala sobre a revolucionária tecnologia ortodôntica Fastbraces",
      publishDate: "27 de outubro de 2022",
      link: "https://www.brasil247.com/parceiros/influenciador-ortodontico-dr-evangelos-viazis-fala-sobre-a-revolucionaria-tecnologia-ortodontica-fastbraces?amp",
      newspaper_name: "Brasil 247",
      image: "jghuyrryu0.jpg",
      language: "portuguese",
    },

    // Various language links:
    // {
    //   title: "xxx",
    //   //publishDate: "September 19, 2022",
    //   link: "xxx",
    //   newspaper_name: "Unknown",
    //   image: "xxx",
    // },

    {
      title: "Fastbraces braces alternative promises results in weeks",
      // publishDate: "September 19, 2022",
      // tslint:disable-next-line:max-line-length
      link: "https://www.9news.com.au/national/fastbraces-braces-alternative-promises-results-in-weeks/8674b4b4-8b38-4d11-9e39-e4c7a115b725",
      newspaper_name: "9news",
      image: "675fuye3yhiud2nko3r2.png",
      language: "english",
    },
    {
      title: "Dentists Offer Fast Braces",
      // publishDate: "September 19, 2022",
      // tslint:disable-next-line:max-line-length
      link: "https://www.nbcboston.com/news/health/dentists-offer-fast-braces_necn/28795/",
      newspaper_name: "NBC10 Boston (WBTS)",
      image: "8t723r67t23ruye.png",
      language: "english",
    },

    {
      // this one is SLIGHTLY DIFFERENTLY WORDED!
      title:
        "Dr. Evangelos Viazis, Talks On The Revolutionary Fastbraces® Orthodontic Technology",
      // publishDate: "September 19, 2022",
      link: "https://yorkpedia.com/2022/09/07/dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
      newspaper_name: "YorkPedia",
      image: "wu998wui209129-0.jpg",
      language: "english",
    },
    {
      title:
        'بفضل إتقانه لتقنية "Fastbraces" الدكتور إيفانجيلوس فيازيس يعيد البسمة لذوي الأسنان المشوهة',
      // publishDate: "September 19, 2022",
      link: "https://sawahpress.com/latest/%d8%a8%d9%81%d8%b6%d9%84-%d8%a5%d8%aa%d9%82%d8%a7%d9%86%d9%87-%d9%84%d8%aa%d9%82%d9%86%d9%8a%d8%a9-fastbraces-%d8%a7%d9%84%d8%af%d9%83%d8%aa%d9%88%d8%b1-%d8%a5%d9%8a%d9%81%d8%a7%d9%86%d8%ac%d9%8a/",
      newspaper_name: "Sawahpress",
      image: "obdg9729g722.jpg",
      language: "arabic",
    },

    {
      title: "The unique technology and benefits of Fastbraces for adults",
      // publishDate: "September 19, 2022",
      link: "ttps://thenassauguardian.com/the-unique-technology-and-benefits-of-fastbraces-for-adults/",
      newspaper_name: "The Nassau Guardian",
      image: "89yr98y2334uuh32hr32r.jpg",
      language: "english",
    },

    {
      title: "Adult braces can be fast and safe",
      // publishDate: "September 19, 2022",
      link: "https://thenassauguardian.com/adult-braces-can-be-fast-and-safe/",
      newspaper_name: "The Nassau Guardian",
      image: "guhg8723g787g832rgiu31.jpg",
      language: "english",
    },

    // {
    //   title: "xxx",
    //   //publishDate: "September 19, 2022",
    //   link: "xxx",
    //   newspaper_name: "Unknown",
    //   image: "xxx",
    // },

    // {
    //   title: "xxx",
    //   //publishDate: "September 19, 2022",
    //   link: "xxx",
    //   newspaper_name: "Unknown",
    //   image: "xxx",
    // },

    // YAHOO LINK WITH MANY SUB-LINKS:
    {
      /*
      title: "Orthodontic Influencer, Dr. Evangelos Viazis, Talks on the Revolutionary Fastbraces Orthodontic Technology",
      publishDate: "September 9, 2022",
      link: "https://www.yahoo.com/now/orthodontic-influencer-dr-evangelos-viazis-231735856.html",
      newspaper_name: "Yahoo News",
      image: "b5f3937561.jpeg",
      */

      // Moved to the front:
      title:
        "Orthodontic Influencer, Dr. Evangelos Viazis, Talks on the Revolutionary Fastbraces Orthodontic Technology",
      publishDate: "September 9, 2022",
      link: "https://apnews.com/press-release/newmediawire/technology-health-texas-dallas-23deac91e320cf194ceabdbf144e3b70",
      newspaper_name: "AP News",
      image: "b5f3937561.jpeg", // Image is repeated!
      language: "english",

      other_links: [
        // This one became the main link, instead of Yahoo:
        // {
        //   publishDate: "September 9, 2022",
        //   link: "https://apnews.com/press-release/newmediawire/technology-health-texas-dallas-23deac91e320cf194ceabdbf144e3b70",
        //   newspaper_name: "AP News",
        // },
        {
          link: "https://www.benzinga.com/pressreleases/22/09/28818765/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-techno",
          newspaper_name: "Benzinga",
          publishDate: "September 9, 2022",
        },
        {
          link: "https://www.snntv.com/story/47255391/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology&T=1lo8ygc8l7a",
          newspaper_name: "Suncoast News Network",
          publishDate: "September 9, 2022",
        },
        {
          link: "https://www.wicz.com/story/47255391/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology&T=1lo8ygc8l7a",
          newspaper_name: "FOX 40",
          publishDate: "September 9, 2022",
        },
        {
          link: "https://www.wpgxfox28.com/story/47255391/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology&T=1lo8ygc8l7a",
          newspaper_name: "WPGX FOX 28",
          publishDate: "September xxx, 2022",
        },
        {
          link: "https://www.wtnzfox43.com/story/47255391/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology&T=1lo8ygc8l7a",
          newspaper_name: "FOX 43 | WTNZ",
          publishDate: "September 9, 2022",
        },
        {
          // tslint:disable-next-line:max-line-length
          // link: "https://www.financeronin.com/2022/09/09/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          link: "https://www.financeronin.com/2022/09/09/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology",
          newspaper_name: "Finance Ronin",
          publishDate: "September 9, 2022",
        },
        {
          // tslint:disable-next-line:max-line-length
          // link: "https://themoneygoals.com/2022/09/09/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          link: "https://themoneygoals.com/2022/09/09/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology",
          newspaper_name: "The Money Goals",
          publishDate: "September 9, 2022",
        },
        {
          // tslint:disable-next-line:max-line-length
          // link: "https://www.timesworld.us/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          link: "https://www.timesworld.us/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology",
          newspaper_name: "Times World USA",
          publishDate: "September 9, 2022",
        },
        {
          // tslint:disable-next-line:max-line-length
          // link: "https://glasgowreport.co.uk/2022/09/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          link: "https://glasgowreport.co.uk/2022/09/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology",
          newspaper_name: "Glasgow Report",
          publishDate: "September 7, 2022",
        },
        {
          // tslint:disable-next-line:max-line-length
          // link: "https://manchestertimes.co.uk/2022/09/07/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          link: "https://manchestertimes.co.uk/2022/09/07/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          newspaper_name: "The Manchester Times",
          publishDate: "September 7, 2022",
        },
        {
          // tslint:disable-next-line:max-line-length
          // link: "https://londonjournal.co.uk/2022/09/07/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          link: "https://londonjournal.co.uk/2022/09/07/orthodontic-influencer-dr-evangelos-viazis-talks-on-the-revolutionary-fastbraces-orthodontic-technology/",
          newspaper_name: "London Journal",
          publishDate: "September 7, 2022",
        },
        // {
        //   link: "xxx",
        //   newspaper_name: "xxx",
        //   publishDate: "September xxx, 2022",
        // },
      ],
    },

    // 10-20 list of popular languages:
    // German:
    {
      title: "Bahnbrechende Kieferorthopädische Fastbraces-Technologie",
      // publishDate: "September 19, 2022",
      link: "https://www.fair-news.de/2932613/bahnbrechende-kieferorthopdische-fastbraces-technologie",
      newspaper_name: "Presseportal fair-NEWS",
      image: "rt43y34y34q3rqwf.jpg",
      language: "german",
    },
    // Turkish:
    {
      // title: "Hızlı diş teli tedavisi fastbraces yöntemi hakkında bilinmesi gerekenler - Sağlık Haberleri - Hürriyet",
      title:
        "Hızlı diş teli tedavisi fastbraces yöntemi hakkında bilinmesi gerekenler - Sağlık Haberleri",
      // publishDate: "September 19, 2022",
      link: "https://www.hurriyet.com.tr/kelebek/saglik/hizli-dis-teli-tedavisi-fastbraces-yontemi-hakkinda-bilinmesi-gerekenler-40210518",
      newspaper_name: "Hürriyet",
      image: "789dgiuwkhjd2.jpg",
      language: "turkish",
    },
    {
      title: "Fastbraces ile hızlı diş teli tedavisi - Estetik",
      // publishDate: "September 19, 2022",
      link: "https://www.milliyet.com.tr/pembenar/fastbraces-ile-hizli-dis-teli-tedavisi-2248708",
      newspaper_name: "Milliyet",
      image: "igudq872187t9712.jpg",
      language: "turkish",
    },
    {
      title: "Yıllarca süren diş teli tedavisine son! - Sağlık Haberleri",
      // publishDate: "September 19, 2022",
      link: "https://www.hurriyet.com.tr/kelebek/saglik/yillarca-suren-dis-teli-tedavisine-son-40104410",
      newspaper_name: "Hürriyet",
      image: "oieuiuw98wd.jpg",
      language: "turkish",
    },
    {
      title: "İleri yaşlarda diş teli taktırmak mümkün mü? - Sağlık Haberleri",
      // publishDate: "September 19, 2022",
      link: "https://www.hurriyet.com.tr/kelebek/saglik/ileri-yaslarda-dis-teli-taktirmak-mumkun-mu-40115551",
      newspaper_name: "Hürriyet",
      image: "80t7y9lo9ibihoil.jpg",
      language: "turkish",
    },
    {
      title:
        "Ortodontinin ünlü ismi Dr. Evangelos Viazis, inovatif Fastbraces teknolojisini değerlendirdi",
      // publishDate: "September 19, 2022",
      link: "https://www.karar.com/saglik-haberleri/ortodontinin-unlu-ismi-dr-evangelos-viazis-inovatif-fastbraces-1698456",
      newspaper_name: "Karar",
      image: "wue2t79dwbiu9w2.jpg",
      language: "turkish",
    },
    {
      title: "Diş çapraşıklığı kısa sürede düzelir mi?",
      // publishDate: "September 19, 2022",
      link: "ttps://www.cnnturk.com/video/saglik/kisa-surede-dis-teli-tedavisi-fastbraces-19-05-2016",
      newspaper_name: "CNN Turk",
      image: "oieuu9geuwp2988y82.jpg",
      language: "turkish",
    },
    // Korean:
    {
      title: " 에반젤로스 비아지스 박사, 패스트브레이스 소개 ",
      publishDate: "November 3, 2022",
      link: "https://dailyhongkong.com/2022/11/03/%EC%97%90%EB%B0%98%EC%A0%A4%EB%A1%9C%EC%8A%A4-%EB%B9%84%EC%95%84%EC%A7%80%EC%8A%A4-%EB%B0%95%EC%82%AC-%ED%8C%A8%EC%8A%A4%ED%8A%B8%EB%B8%8C%EB%A0%88%EC%9D%B4%EC%8A%A4-%EC%86%8C%EA%B0%9C/",
      newspaper_name: "Daily Hongkong",
      image: "dhk975765.jpg",
      language: "korean", // South Korean
    },

    // Less popular languages---------------------------------------------------:
    // Italian:
    {
      title:
        "L’influencer ortodontico, Dr. Evangelos Viazis, parla della rivoluzionaria tecnologia ortodontica Fastbraces",
      // publishDate: "September 19, 2022",
      link: "https://bemyguru.it/salute/linfluencer-ortodontico-dr-evangelos-viazis-parla-della-rivoluzionaria-tecnologia-ortodontica-fastbraces/",
      newspaper_name: "Be My Guru",
      image: "u83giugeu3889e3.jpg",
      language: "italian",
      other_links: [
        {
          link: "https://www.nellanotizia.net/scheda_it_119135_L-influencer-ortodontico,-Dr--Evangelos-Viazis,-parla-della-rivoluzionaria-tecnologia-ortodontica-Fastbraces_1.html",
          newspaper_name: "NellaNotizia",
        },
      ],
    },

    {
      title:
        "L’influencer ortodontico, Dr. Evangelos Viazis, parla della rivoluzionaria tecnologia ortodontica Fastbraces - Four Fancy Magazine",
      // publishDate: "September 19, 2022",
      link: "https://www.fourfancy.it/linfluencer-ortodontico-dr-evangelos-viazis-parla-della-rivoluzionaria-tecnologia-ortodontica-fastbraces/",
      newspaper_name: "Four Fancy Magazine",
      image: "io98ye389y32e3.png",
      language: "italian",
    },

    // Even less popular languages:
     {
      title: "Η νέα ορθοδοντική τεχνολογία Fastbraces® που δίνει τέλος στα στραβά δόντια",
      publishDate: "April 12, 2022",
      link: "https://blog.doctoranytime.gr/siderakia-fastbraces",
      newspaper_name: "Doctor Anytime Blog",
      image: "siderakia_gh7tyf.jpg",
      language: "greek",
    },
    {
      title:
        "Oδοντίατρος Ευάγγελος Bιάζης: Στόχος μου είναι κάθε άνθρωπος να μπορεί να χαμογελάσει γρήγορα",
      publishDate: "November 13, 2022",
      link: "https://fimotro.gr/odontiatros-evangelos-biazis-stochos-mou-einai-kathe-anthropos-na-borei-na-chamogelasei-grigora",
      newspaper_name: "Fimotro",
      image: "ghfefgyuw31282ge.png",
      language: "greek",
    },
    {
      title: "Έτσι θα έχετε γρήγορα τα ίσια δόντια που ονειρεύεστε",
      // publishDate: "September 19, 2022",
      link: "https://www.protothema.gr/ugeia/article/863508/etsi-tha-ehete-grigora-ta-isia-dodia-pou-oneireueste/",
      newspaper_name: "Protothema",
      image: "protothema6752567ftydfgdm.jpg",
      language: "greek",
    },
    {
      title:
        "Απόκτησε ένα ονειρικό χαμόγελο με ίσια και λαμπερά δόντια μέσα σε 120 ημέρες",
      // publishDate: "September 19, 2022",
      link: "https://www.alphanews.live/health/apoktise-ena-oneiriko-hamogelo-me-isia-kai-lampera-dontia-mesa-se-120-imeres",
      newspaper_name: "Alphanews",
      image: "ntfhsd78te2.png",
      language: "greek",
    },

    // Moved down:
    {
      title:
        "Ortodontisk influencer, Dr. Evangelos Viazis, talar om den revolutionära fastbraces ortodontiska teknologin",
      publishDate: "November 5, 2022",
      link: "https://blogg.improveme.se/expertreviews/2022/11/05/talar-om-den-revolutionara-fastbraces-ortodontiska-teknologin/",
      newspaper_name: "Improveme.se",
      image: "uye2187t76t32yugyu.jpg",
      language: "swedish",
      other_links: [
        {
          publishDate: "November 5, 2022",
          link: "https://www.devote.se/Health_Fitness/ortodontisk-influencer-dr-evangelos-viazis-talar-om-den-revolutionaera-fastbraces-ortodontiska-teknologin-31437893",
          newspaper_name: "Devote.se",
        },
      ],
    },
  ];
}
